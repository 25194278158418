/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import {
    RenderWhenVisibleComponent as SourceRenderWhenVisible,
} from 'SourceComponent/RenderWhenVisible/RenderWhenVisible.component';

/** @namespace Satisfly/Component/RenderWhenVisible/Component */
export class RenderWhenVisibleComponent extends SourceRenderWhenVisible {
    static propTypes = {
        ...super.propTypes,
        offset: PropTypes.number,
    };

    static defaultProps = {
        ...super.defaultProps,
        offset: 0,
    };

    renderVisibilitySensor() {
        const { offset = 0 } = this.props;

        return (
            <InView rootMargin={ `${ offset }px` } onChange={ this.handleVisibilityToggle }>
                { this.renderFallback() }
            </InView>
        );
    }
}

export default RenderWhenVisibleComponent;
